@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: 'Grandstander', cursive;
}

html {
  background-color: #fff3db;
}

.page {
  color: #7bb155;
  background-color: #fff3db;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.order {
  color: #7bb155;
  background-color: #fff3db;
  width: 100vw;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
}

form {
  color: #7bb155;
  background-color: #fff3db;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
}
.main-container {
  height: 60vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-container img {
  width: 300px;
  margin: 20px;
}

.header {
  font-size: 60px;
  font-family: 'DT Getai Grotesk';
  max-width: 550px;
  text-align: center;
  line-height: 90%;
  margin-bottom: 20px;
  width: 100%;
  align-self: center;
}

.time {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 20px;
  right: 20px;
}

.actualtime {
  font-size: 48px;
  font-family: 'DT Getai Grotesk';
}

.comein {
  background-color: #fe929c;
  color: #fff3db;
  border: none;
  border-radius: 100px;
  font-size: 20px;
  font-family: 'Grandstander', cursive;
  padding: 20px 40px 20px 40px;
  text-decoration: none;
  margin: 20px;
}

.comein:hover {
  text-decoration-line: underline;
  text-decoration-color: rgb(219, 75, 56);
  text-decoration-style: wavy;
  text-underline-offset: 5px;
  text-decoration-thickness: 1.5px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 80vw;
}

label {
  padding: 10px;
}

.desc {
  padding: 10px;
  font-size: 12px;
  color: #fa885b;
}

.menuHeader {
  margin: 30px 15px 15px 15px;
  color: #ff7337;
  font-weight: 800;
}
